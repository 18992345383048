import { Button } from 'components/Button'
import { Card } from 'components/card'
import { Confirm, ConfirmDialogContext } from 'components/Confirm'
import { DialogActions, DialogContent, DialogSecondaryButton } from 'components/dialog'
import { Box, Stack } from 'components/layout'
import { Text } from 'components/Text'
import { useHasShowAdvancedStats } from 'contexts/UserContext'
import { VoteTarget } from 'features/events/data/eventsApi'
import { useGetVoteCampaign, useSetVoteCountforCampaign } from 'features/feed/data/feedQueries'
import { FeedEntryComponent } from 'features/feed/types/feedTypes'
import { useNotifications } from 'features/notifications'
import { LocalisationKey } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import { castVote } from 'lib/liveops/actions/inventory'
import { useSelector } from 'lib/store'
import { lighten } from 'polished'
import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { FeedEntryBase } from './FeedEntryBase'
import { useIncrementActivityScore } from '../../../../hooks/useIncrementActivityScore'

export const VotingEntry: FeedEntryComponent = ({ entry }) => {
    const { data: campaign } = useGetVoteCampaign(entry.campaignId)
    const voteState = useSelector(state => state.client.vote)
    const dispatch = useDispatch()
    const incrementActivityScore = useIncrementActivityScore()
    const t = useTranslate()
    const inventory = useSelector(state => state.client.inventory)
    const hasShowVoteResults = useHasShowAdvancedStats()
    const { notify } = useNotifications()
    const [isError, setIsError] = useState(false)

    React.useEffect(() => {
        if (!campaign || !campaign.targets) return
        const voteTargetIds = campaign.targets.map((target: VoteTarget) => target.id)

        if (voteState.phase === 'error' && voteState.target && voteTargetIds.includes(voteState.target)) {
            setIsError(true)
            notify(t('error::somethingWentWrong'), { variant: 'error' })
        }
    }, [voteState, notify, t, campaign])

    if (!campaign || !campaign.targets) {
        return null
    }

    const campaignId = entry.campaignId
    if (!campaignId) return null

    const targets = campaign.targets
    const vote = async (id: string) => {
        if (voteState.phase !== 'begin') {
            dispatch(castVote(campaignId, id, 1, true))
            incrementActivityScore('news-feed:voting:cast-vote')
        }
    }

    const closedVotesIds = ['610bf5b697e145ca0d7dc730']

    const allVotes = targets.reduce((prev, { voteCount }) => prev + (voteCount ?? 0), 0)

    const voteTargetIds = targets.map(target => target.id)

    const votes = inventory
        ? inventory.filter(item => item.kind === 'vote' && voteTargetIds.includes(item.metadata.target))
        : []

    const isVoted = votes.length !== 0 && voteState.phase !== 'begin'

    const isVoteInProgress = voteState.phase === 'begin' && voteTargetIds.includes(voteState.target ?? '')

    const leftSideRate =
        targets[0].voteCount === 0 && targets[1].voteCount === 0
            ? 50
            : Math.round(((targets[0].voteCount ?? 0) / allVotes) * 100)
    const rightSideRate =
        targets[0].voteCount === 0 && targets[1].voteCount === 0
            ? 50
            : Math.round(((targets[1].voteCount ?? 0) / allVotes) * 100)

    if (!isVoted && closedVotesIds.includes(campaignId)) {
        return null
    }

    return (
        <FeedEntryBase entry={entry}>
            <Card id={campaignId}>
                <Box position="relative">
                    <Versus>{t('feed::voting::versus')}</Versus>
                    <Grid>
                        {targets.map(target => (
                            <Target
                                target={target}
                                key={target.id}
                                isVoted={isVoted}
                                isVoteInProgress={isVoteInProgress}
                                vote={() => vote(target.id)}
                                champaignId={campaignId}
                                isError={isError}
                            />
                        ))}
                    </Grid>
                    <Content $isVoted={isVoted}>
                        {isVoted ? (
                            <Stack>
                                <Text variant="h3">{t(entry.body as LocalisationKey)}</Text>
                                {(campaign.hideVoteResults !== true) && <div>
                                    <ResultText variant="body2">{t('feed::voting::results')}:</ResultText>
                                    <ProgessContainer>
                                        {hasShowVoteResults && (
                                            <>
                                                <LeftCount>{targets[0].voteCount}</LeftCount>
                                                <RightCount>{targets[1].voteCount}</RightCount>
                                            </>
                                        )}
                                        <LeftSide style={{ width: `${leftSideRate}%` }}>
                                            {leftSideRate >= 10 && <StyledText>{leftSideRate}%</StyledText>}
                                        </LeftSide>
                                        <RightSide style={{ width: `${rightSideRate}%` }}>
                                            {rightSideRate >= 10 && <StyledText>{rightSideRate}%</StyledText>}
                                        </RightSide>
                                    </ProgessContainer>
                                </div>}
                                <Text variant="h3">{t('feed::voting::voted')}</Text>
                            </Stack>
                        ) : (
                            <Text variant="h3">{t(entry.body as LocalisationKey)}</Text>
                        )}
                    </Content>
                </Box>
            </Card>
        </FeedEntryBase>
    )
}

const LeftCount = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15px, 30%);
    color: #fff;
`

const RightCount = styled.span`
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-15px, 30%);
    color: #fff;
`

const Versus = styled.span`
    position: absolute;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: ${props => props.theme.spacing(2, 4)};
    background-color: ${props => props.theme.palette.background.paper};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    font-weight: 900;
    font-size: 2rem;
    color: ${props => props.theme.palette.primary.main};
    z-index: 1;
`

const ResultText = styled(Text)`
    text-align: center;
    opacity: 0.8;
`

interface TargetProps {
    target: VoteTarget
    isVoted: boolean
    isVoteInProgress: boolean
    vote: () => void
    champaignId: string
    isError: boolean
}

const Target: React.FC<TargetProps> = ({ target, isVoted, isVoteInProgress, vote, champaignId, isError }) => {
    const voteState = useSelector(state => state.client.vote)
    const inventory = useSelector(state => state.client.inventory)

    const setVoteCount = useSetVoteCountforCampaign()
    const t = useTranslate()

    React.useEffect(() => {
        if (voteState.phase === 'done' && voteState.target === target.id) {
            setVoteCount(champaignId, target.id)
        }
    }, [champaignId, setVoteCount, target.id, voteState])

    const isMyVote = !!inventory?.find(item => item.metadata?.target === target.id) && voteState.phase !== 'begin'

    const btnTextFromDescription = (() => {
        if (target.description) {
            return t(target.description as LocalisationKey, { defaultValue: null }) ?? t('feed::voting::button')
        }

        return t('feed::voting::button')
    })()

    return (
        <Root>
            <ImageContainer>
                <Image src={target.thumbnail} alt={target.name} $isMyVote={isMyVote} $isVoted={isVoted} />
                {!isVoted && (
                    <Confirm action={vote} dialog={ConfirmDialog} dialogProps={{ hideCloseButton: true }}>
                        {confirm => (
                            <StyledButton
                                variant="outlined"
                                isLoading={isVoteInProgress}
                                onClick={confirm}
                                disabled={isError}
                            >
                                {btnTextFromDescription}
                            </StyledButton>
                        )}
                    </Confirm>
                )}
            </ImageContainer>
        </Root>
    )
}

const StyledButton = styled(Button)`
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: ${props => props.theme.palette.background.paper};
    transform: translateX(-50%) translateY(50%);
    box-shadow: ${props => props.theme.shadows[1]};

    &:hover,
    &:focus-visible {
        background-color: ${props => props.theme.palette.background.paper};
        box-shadow: ${props => props.theme.shadows[2]};
    }
`

const StyledText = styled(Text)`
    z-index: 1;
    position: relative;
    color: ${props => props.theme.palette.primary.contrastText};
    font-weight: 600;
`

const ProgessContainer = styled.div`
    width: 100%;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    position: relative;
    overflow: hidden;
    display: flex;
    height: 2rem;
`

const LeftSide = styled.div`
    background: ${props => props.theme.palette.primary.main};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const RightSide = styled.div`
    height: 100%;
    background-color: ${props => lighten(0.1, props.theme.palette.primary.light)};
    display: flex;
    align-items: center;
    justify-content: center;
`

const ImageContainer = styled.div`
    height: 20rem;
    width: 100%;
    position: relative;
`

const Image = styled.img<{ $isMyVote: boolean; $isVoted: boolean }>`
    height: 100%;
    width: 100%;
    object-fit: cover;
    ${props => props.$isVoted && `filter:grayscale(${props.$isMyVote ? 0 : 1})`};
    transition: all ${props => props.theme.transitions.duration.short}ms;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`

const Content = styled(Stack) <{ $isVoted: boolean }>`
    padding: ${props => props.theme.spacing(props.$isVoted ? 4 : 8, 4, 4, 4)};
`

const Root = styled.div`
    &:first-of-type ${Image} {
        border-right: 4px solid ${props => props.theme.palette.background.paper};
    }
    &:last-of-type ${Image} {
        border-left: 4px solid ${props => props.theme.palette.background.paper};
    }
`

export const ConfirmDialog: React.FC<ConfirmDialogContext> = ({ positiveButtonProps, negativeButtonProps }) => {
    const t = useTranslate()

    return (
        <>
            <DialogContent>
                <Text variant="h3"> {t('feed::voting::warningmessage')}</Text>
                <Text>{t('feed::voting::warningmessage02')}!</Text>
            </DialogContent>
            <DialogActions>
                <DialogSecondaryButton {...negativeButtonProps}>{t('general::cancel')}</DialogSecondaryButton>
                <Button variant="contained" {...positiveButtonProps}>
                    {t('general::vote')}
                </Button>
            </DialogActions>
        </>
    )
}

import { ApiTypes } from 'lib/api/types'
import { ApiBase } from 'lib/api/ApiBase'
import { createEnvironmentVariable } from 'utils/env'
import { events, eventsV2 } from './eventData'
import { UserState } from 'contexts/UserContext'
import { DynamicEventDefinition, EventDefinition } from '../types/eventTypes'
import { hasGrant } from 'lib/grants'
import { SHAREABLE_TRAINERCONTEST_PROJECT_TAG } from 'features/projects'
import { AxiosInstance } from 'axios'
import { filterNulls } from 'utils/arrayUtilts'
import { ActivityEvent } from '../activity-events/types/activityEventTypes'
import dayjs from 'dayjs'
import { DynamicManagableEventDefinition, ManagableEventDefinition } from '../types/eventTypesV2'
import { EventsCampaigns } from './EventsCampaign'

export type VoteTarget = ApiTypes['vote-target'] & { id: string }
export type VoteCampaign = ApiTypes['vote-campaign'] & { targets: VoteTarget[] } & { id: string }
export type FileWithEmbeddedOwner = Omit<ApiTypes['file'], 'owner'> & { owner: ApiTypes['account'] }
export type TrainerContestResults = {
    contestData: VoteTarget | undefined
    file: FileWithEmbeddedOwner | undefined
}

function objectListToIdList(objectList: any[]) {
    return objectList.map(object => (typeof object === 'string' ? object : object.id || object._id))
}

export class EventsApi extends ApiBase {
    constructor(client: AxiosInstance, private getUserId: () => string | null) {
        super(client)
    }

    private getActivityEvents = async () => {
        try {
            const activityEvents = await this.client.get<ActivityEvent[]>(`events/my`, { params: { showOld: true } })
            return activityEvents.data
        } catch (e) {
            console.error(e)
            return false
        }
    }

    public createActivityEvent = async (event: ActivityEvent) => {
        try {
            const submittableEvent: any = { ...event }
            delete submittableEvent.id
            delete submittableEvent._id
            delete submittableEvent.__v
            delete submittableEvent.createdAt
            delete submittableEvent.updatedAt

            submittableEvent.published = false
            submittableEvent.closed = false

            submittableEvent.tasks = objectListToIdList(submittableEvent.tasks)

            for (let milestone of submittableEvent.milestones) {
                delete milestone.id
                delete milestone._id
                delete milestone.__v
            }

            for (let subEvent of submittableEvent.subEvents) {
                delete subEvent.id
                delete subEvent._id
                delete subEvent.__v

                for (let trigger of subEvent.triggers) {
                    delete trigger.id
                    delete trigger._id
                    delete trigger.__v
                }

                for (let winnerAction of subEvent.winnerActions) {
                    delete winnerAction.id
                    delete winnerAction._id
                    delete winnerAction.__v
                }
            }

            const activityEvent = await this.client.post<ActivityEvent>(`events`, submittableEvent)
            return activityEvent.data
        } catch (e) {
            console.error(e)
            return null
        }
    }

    public updateActivityEvent = async (event: ActivityEvent) => {
        try {
            const submittableEvent: any = { ...event }
            delete submittableEvent.__v

            submittableEvent.tasks = objectListToIdList(submittableEvent.tasks)

            for (let milestone of submittableEvent.milestones) {
                delete milestone.__v
            }

            for (let subEvent of submittableEvent.subEvents) {
                delete subEvent.__v

                for (let trigger of subEvent.triggers) {
                    delete trigger.__v
                }

                for (let winnerAction of subEvent.winnerActions) {
                    delete winnerAction.__v
                }
            }

            const activityEvent = await this.client.put<ActivityEvent>(
                `events/${submittableEvent._id}`,
                submittableEvent,
            )
            return activityEvent.data
        } catch (e) {
            console.error(e)
            return null
        }
    }

    getEventsForUser = async (user: UserState) => {
        const activityEvents = await this.getActivityEvents()

        const filterByCountry = (event: EventDefinition | ManagableEventDefinition) => {
            if (!event.filters?.countries) {
                return true
            }
            return event.filters.countries.includes(user.i18n.country)
        }

        const filterByGender = (entry: EventDefinition | ManagableEventDefinition) =>
            entry.filters?.gender ? entry.filters?.gender === user.crm.gender : true

        const filterByGrant = (entry: EventDefinition | ManagableEventDefinition) =>
            entry.filters?.grant ? hasGrant(user.grants)(entry.filters.grant) : true

        const filterByEnabled = (entry: EventDefinition | ManagableEventDefinition) =>
            entry.enabled !== undefined ? entry.enabled : true

        const filterByStartDate = (entry: EventDefinition | ManagableEventDefinition) =>
            entry.startDate ? dayjs(entry.startDate).isBefore(dayjs()) : true

        const applyDynamicEvents = (
            entry:
                | EventDefinition
                | DynamicEventDefinition
                | ManagableEventDefinition
                | DynamicManagableEventDefinition,
        ) => (typeof entry === 'function' ? entry(user) : entry)

        const eventsWithTemplate = (activityEvents || []).map(event => {
            if (event.template) {
                return {
                    ...event,
                    managableEvent: {
                        slug: event.slug,
                        groupSlug: event.group,
                        ongoing: !event.closed,
                        title: event.titleKey,
                        closedTitle: event.closedTitleKey || event.titleKey,
                        banner: {
                            image: event.banner,
                            text: event.bannerTitleKey,
                        },
                        thumbnail: event.thumbnail,
                        startDate: event.startDate,
                        template: event.template,
                        templateData: event.templateData,
                        state: [{ state: 'default' }],
                        enabled: true,
                        detailsBanner: event.detailsBanner,
                        original: event,
                    },
                }
            }
            return event
        })

        const normalizedEvents = [...eventsV2, ...events].map(applyDynamicEvents)

        const filteredEvents = normalizedEvents
            .filter(filterNulls)
            .filter(filterByStartDate)
            .filter(filterByEnabled)
            .filter(filterByCountry)
            .filter(filterByGender)
            .filter(filterByGrant)

        return {
            normalEvents: filteredEvents,
            activityEvents: eventsWithTemplate,
            hasEventsGrant: activityEvents !== false,
        }
    }

    getVoteTargets = async (campaign: string, disableVoteCountSort?: boolean) => {
        const res = await this.client.get<VoteTarget[]>('/vote-targets', {
            params: {
                campaign: campaign,
                sort: disableVoteCountSort ? undefined : '-voteCount',
            },
        })

        return res.data
    }

    getCookiesForProjects = async (ids: string[]) => {
        if (ids.length === 0) {
            return []
        }

        const res = await this.client.get<{ file: string }[]>('/cookies/my', {
            params: {
                'where[file][in]': ids.join(),
            },
        })
        return res.data.map(cookie => cookie.file)
    }

    getMyShareableContestProjects = async () => {
        return this.client.get<ApiTypes['file'][]>('/files', {
            params: {
                owner: this.getUserId(),
                'where[kind][in]': 'project/mix,project/blocks',
                deleted: false,
                sort: '-updatedAt',
                'where[tags][eq]': SHAREABLE_TRAINERCONTEST_PROJECT_TAG,
                'where[shareSpecial][ne]': SHAREABLE_TRAINERCONTEST_PROJECT_TAG,
                shared: false,
            },
        })
    }

    getMyContestProjects = async () => {
        return this.client.get<ApiTypes['file'][]>(`/files`, {
            params: {
                owner: this.getUserId(),
                'where[tags][eq]': SHAREABLE_TRAINERCONTEST_PROJECT_TAG,
                deleted: false,
                shareSpecial: SHAREABLE_TRAINERCONTEST_PROJECT_TAG,
            },
        })
    }

    getTrainerContestFinalResults = async () => {
        const VOTE_CAMPAIGN = createEnvironmentVariable(EventsCampaigns['TRAINER_CONTEST_2020'])
        const res = await this.client.get<VoteTarget[]>('/vote-targets', {
            params: {
                campaign: VOTE_CAMPAIGN,
            },
        })

        const filteredData = res.data.filter(contest => contest.source)

        const filesData = await this.client.get<FileWithEmbeddedOwner[]>('/files', {
            params: {
                shareSpecial: 'trainercontest2020:general',
                embed: 'owner',
            },
        })

        const contestWithFileData = filteredData.map(contest => {
            return {
                contestData: contest,
                file: filesData.data.find(file => file.id === contest.source),
            }
        })
        contestWithFileData.sort((a, b) => ((a.file?.cookieCount ?? 0) > (b.file?.cookieCount ?? 0) ? -1 : 1))

        return contestWithFileData
    }

    nominateProject = async (id: string) => {
        return this.client.post(`/files/${id}/share`, null, {
            params: {
                shareSpecial: SHAREABLE_TRAINERCONTEST_PROJECT_TAG,
                voteCampaign: '60e578779913d80dc86c42d6',
                createVoteTarget: true,
            },
        })
    }

    unshareContestProject = async (id: string) => {
        return this.client.delete(`/files/${id}/share`, {
            params: {
                shareSpecial: SHAREABLE_TRAINERCONTEST_PROJECT_TAG,
            },
        })
    }

    getTrainerContestForVoting2021 = async () => {
        return this.client.get<ApiTypes['file'][]>(`/files`, {
            params: {
                'where[tags][eq]': SHAREABLE_TRAINERCONTEST_PROJECT_TAG,
                deleted: false,
                shareSpecial: SHAREABLE_TRAINERCONTEST_PROJECT_TAG,
            },
        })
    }

    getTrainerContestFinalResults2021 = async () => {
        return this.client.get<FileWithEmbeddedOwner[]>(`/files`, {
            params: {
                'where[tags][eq]': SHAREABLE_TRAINERCONTEST_PROJECT_TAG,
                deleted: false,
                shareSpecial: SHAREABLE_TRAINERCONTEST_PROJECT_TAG,
                embed: 'owner',
            },
        })
    }
}

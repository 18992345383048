import { VoteCampaign } from 'features/events/data/eventsApi'
import { ApiBase } from 'lib/api/ApiBase'
import { FeedEntry, FeedType, NewsFeeds } from '../types/feedTypes'

export class FeedApi extends ApiBase {
    getVoteCampaign = async (id: string) => {
        const res = await this.client.get<VoteCampaign>(`vote-campaigns/${id}?embed=targets`)

        return res.data
    }

    getFeedEntries = async (type: FeedType, limit: number, skip: number) => {
        const params: Record<string, any> = {
            limit,
            skip,
        }

        if (type === 'trainer') {
            params.onlyTrainer = true
        }

        const res = await this.client.get<NewsFeeds>('news-feeds/my', { params })

        return res.data
    }

    getFeedEntry = async (id: string) => {
        return this.client.get<FeedEntry>(`news-feeds/${id}`)
    }
}

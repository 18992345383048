import image0 from './67475f5b932fddb2ca87fc2f.png'
import image1 from './67475f91932fddb2ca880a9c.png'
import image2 from './674760669d90878cebd8beeb.png'
import image3 from './67475fb99d90878cebd8a077.png'
import image4 from './67475fdb9d90878cebd8a72d.png'
import image5 from './67475f169d90878cebd879f6.png'
import image6 from './674760439d90878cebd8b8ff.png'
import image7 from './67475ffe9d90878cebd8ac30.png'
import image8 from './674760219d90878cebd8b3bf.png'
import image9 from './674760989d90878cebd8c85a.png'

type ImagesRecord = Record<string, string>

export const images: ImagesRecord = {
    '67475f5b932fddb2ca87fc2f': image0,
    '67475f91932fddb2ca880a9c': image1,
    '674760669d90878cebd8beeb': image2,
    '67475fb99d90878cebd8a077': image3,
    '67475fdb9d90878cebd8a72d': image4,
    '67475f169d90878cebd879f6': image5,
    '674760439d90878cebd8b8ff': image6,
    '67475ffe9d90878cebd8ac30': image7,
    '674760219d90878cebd8b3bf': image8,
    '674760989d90878cebd8c85a': image9,
}

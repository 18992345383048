import React, { useState } from 'react'
import { SecretCodeUnlocker } from 'features/secret-code'
import { useLocationTabs } from 'hooks/useLocationTabs'
import { Dialog, DialogTitle, DialogSecondaryButton, DialogForm, DialogPrimaryButton } from 'components/dialog'
import { Controller, useForm } from 'react-hook-form'
import { Stack } from 'components/layout'
import { TextField } from 'components/form'
import { Select, Option } from 'components/form'
import { Flex } from 'components/layout'
import { Loader } from 'components/Loader'
import { ApiTypes } from 'lib/api'
import { SelectBox, SelectBoxItem } from 'components/form'
import { BloxIcon, MixIcon, QuestionCircleIcon } from 'components/icons'
import {
    canUnlockSecretCode,
    canUserCreateBloxProject,
    canUserCreateMixProject,
    canUserCreateProject,
    canUserCreatePythonProject,
    canUserCreateStageScriptProject,
    useUserState,
} from 'contexts/UserContext'
import { DialogErrorMessage } from 'components/dialog'
import { Text } from 'components/Text'
import { Tab, Tabs } from 'components/tabs'
import { createHashDialog } from 'hooks/createHashDialog'
import {
    getProjectNameValidationMessage,
    MAX_PROJECT_NAME_LENGTH,
    projectNameRules,
    PROJECT_NAME_NOT_UNIQUE_FIELD_ERROR,
} from './projectHelpers'
import { useProjectActions } from './useProjectActions'
import { useBlockGalleriesQuery } from './projectQueries'
import { ErrorMessage } from 'components/ErrorMessage'
import { Collapse } from 'components/Collapse'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { useTranslate } from 'lib/i18n/useTranslate'
import { MIX_PALETTE_ID, PYTHON_PALETTE_ID, ProjectLanguage } from './createProjectFormData'
import { CookieHunt } from 'features/cookie-hunt'
import { useIncrementActivityScore } from '../../hooks/useIncrementActivityScore'
import { getIdeCreateUrl } from 'lib/api/helpers'
import { CLASSROOM_PROJECT_TAG } from './ProjectsApi'
import { useHistory, useLocation } from 'react-router-dom'
import { useMiniQuizAccessLevel } from 'features/mini-quizzes/helpers/miniQuizHelpers'
import { countTruthyValues } from 'utils/arrayUtilts'
import { ProjectLabelSelect } from './ProjectLabelSelect'
import { useHasGrant } from 'lib/grants'

const [newProjetDialogTo, NewProjectDialogController] = createHashDialog('create-project', { useLocationState: true })

export { newProjetDialogTo }

export const NewProjectDialog: React.FC = () => {
    const user = useUserState()
    const canCreateProject = canUserCreateProject(user)
    const canUnlockSecret = canUnlockSecretCode(user, 'project')
    const miniQuizAccessLevel = useMiniQuizAccessLevel()
    const hasCreate = canCreateProject || miniQuizAccessLevel === 'full'
    const { value, tabProps } = useLocationTabs('create-project-dialog', hasCreate ? 'create' : 'unlock')
    const t = useTranslate()

    if (!hasCreate && !canUnlockSecret) {
        return null
    }

    return (
        <NewProjectDialogController>
            {({ onClose, open }) => (
                <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
                    <DialogTitle disableTypography style={{ paddingBottom: hasCreate ? 0 : undefined }}>
                        <Text variant="h3" component="h2">
                            {t('projects::newProject')}
                        </Text>
                        {hasCreate && canUnlockSecret && (
                            <Tabs value={value}>
                                <Tab {...tabProps('create')} label={t('projects::createProject')}></Tab>
                                <Tab
                                    data-testid="new-project-dialog-unlock"
                                    {...tabProps('unlock')}
                                    label={t('projects::unlockProject')}
                                ></Tab>
                            </Tabs>
                        )}
                    </DialogTitle>

                    {hasCreate && value === 'create' && (
                        <ErrorBoundary fallback={<DialogErrorMessage close={onClose} />}>
                            <NewProjectPreloader close={onClose} />
                        </ErrorBoundary>
                    )}
                    {value === 'unlock' && canUnlockSecret && (
                        <ErrorBoundary fallback={<DialogErrorMessage close={onClose} />}>
                            <SecretCodeUnlocker close={onClose} primaryLabel={t('projects::unlockProject')} />
                        </ErrorBoundary>
                    )}
                </Dialog>
            )}
        </NewProjectDialogController>
    )
}

interface NewProjectPreloaderProps {
    close: () => void
}

const NewProjectPreloader: React.FC<NewProjectPreloaderProps> = props => {
    const { data: blockGalleries, status: blockGalleryStatus } = useBlockGalleriesQuery()

    if (blockGalleryStatus === 'error') {
        return <DialogErrorMessage close={props.close} />
    }

    if (!blockGalleries || blockGalleryStatus === 'loading') {
        return (
            <Flex height={400} justify="center" align="center">
                <Loader />
            </Flex>
        )
    }

    const selectableBlockGalleries = blockGalleries.data.filter(
        gallery => gallery.id !== MIX_PALETTE_ID && gallery.id !== PYTHON_PALETTE_ID,
    )

    return <NewProject blockGalleries={selectableBlockGalleries} {...props} />
}

const projectTypes = ['blox', 'mix', 'mini-quiz'] as const
type ProjectType = typeof projectTypes[number]

type FormValues = {
    name: string
    type: ProjectType
    language: ProjectLanguage
    blockGallery: string
    labels: string[]
}

interface NewProjectProps extends NewProjectPreloaderProps {
    blockGalleries: ApiTypes['block-gallery'][]
}

const NewProject: React.FC<NewProjectProps> = ({ close, blockGalleries }) => {
    const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle')
    const t = useTranslate()
    const user = useUserState()
    const incrementActivityScore = useIncrementActivityScore()
    const miniQuizAccessLevel = useMiniQuizAccessLevel()
    const history = useHistory()
    const canCreateBloxProjects = canUserCreateBloxProject(user) && blockGalleries.length > 0
    const canCreateMixProjects = canUserCreateMixProject(user)
    const canCreatePythonProjects = canUserCreatePythonProject(user)
    const canCreateStageScriptProjects = canUserCreateStageScriptProject(user)
    const canChooseLanguage = canCreatePythonProjects && canCreateStageScriptProjects
    const canCreateProject = canCreateBloxProjects || canCreateMixProjects
    const createsMixByDefault = user.account.primaryKind === 'student'
    const canSelectBlockGallery = blockGalleries.length > 1
    const { createProject, createMiniQuiz } = useProjectActions()
    const location = useLocation<{ type?: ProjectType }>()
    const hasGrant = useHasGrant()

    const defaultType = (() => {
        const locationStateTypeOverride =
            location.state?.type && projectTypes.includes(location.state.type) ? location.state.type : undefined
        if (locationStateTypeOverride) {
            return locationStateTypeOverride
        }

        if (canCreateProject) {
            if (canCreateMixProjects && (createsMixByDefault || !canCreateBloxProjects)) {
                return 'mix'
            }
            return 'blox'
        }
        return 'mini-quiz'
    })()

    const { register, control, handleSubmit, errors, watch, setError, setValue } = useForm<FormValues>({
        defaultValues: {
            name: '',
            type: defaultType,
            blockGallery: blockGalleries.length === 1 ? blockGalleries[0].id : '',
            labels: [],
            language:
                canCreateMixProjects && canCreatePythonProjects
                    ? 'python'
                    : 'stagescript',
        },
        shouldUnregister: false,
    })

    const onSubmit = handleSubmit(async ({ name, type, blockGallery, labels, language }) => {
        if (status !== 'loading') {
            setStatus('loading')
            try {
                const res =
                    type === 'mini-quiz'
                        ? await createMiniQuiz(name)
                        : await createProject(name, type, blockGallery, labels, language)
                if (res.success === false) {
                    if (res.error === 'NAME_NOT_UNIQUE') {
                        setStatus('idle')
                        setError('name', { type: PROJECT_NAME_NOT_UNIQUE_FIELD_ERROR, shouldFocus: true })
                    }
                } else {
                    incrementActivityScore(
                        res.project.data.tags?.includes(CLASSROOM_PROJECT_TAG)
                            ? 'classroom:owned-project:create'
                            : 'community:owned-project:create',
                    )
                    if (type === 'mini-quiz') {
                        close()
                        setTimeout(() => {
                            history.push(`/app/quiz-editor/${res.project.data.id}`)
                        }, 50)
                    } else {
                        window.open(getIdeCreateUrl(`/ide/${res.project.data.id}`))
                        close()
                    }
                }
                setStatus('idle')
            } catch (e) {
                setStatus('error')
            }
        }
    }, console.error)

    return (
        <DialogForm
            onSubmit={onSubmit}
            actions={
                <>
                    <DialogSecondaryButton onClick={close}>{t('general::cancel')}</DialogSecondaryButton>
                    <CookieHunt name="new-project-dialog">
                        <DialogPrimaryButton isLoading={status === 'loading'} type="submit">
                            {t('projects::createProject')}
                        </DialogPrimaryButton>
                    </CookieHunt>
                </>
            }
        >
            <Stack>
                <Collapse unmountOnExit in={status === 'error'}>
                    <ErrorMessage />
                </Collapse>
                <TextField
                    data-testid="new-project-dialog-name"
                    errorText={getProjectNameValidationMessage(errors.name, t)}
                    ref={register(projectNameRules)}
                    disabled={status === 'loading'}
                    name="name"
                    inputProps={{
                        maxLength: MAX_PROJECT_NAME_LENGTH,
                        autocomplete: 'off',
                    }}
                    autoFocus
                    label={t('projects::name')}
                />

                {countTruthyValues([canCreateMixProjects, canCreateBloxProjects, miniQuizAccessLevel === 'full']) >
                    1 && (
                    <div>
                        <Controller
                            control={control}
                            name="type"
                            as={
                                <SelectBox
                                    data-testid="new-project-dialog-type"
                                    label={t('projects::type')}
                                    disabled={status === 'loading'}
                                >
                                    {canCreateBloxProjects && (
                                        <SelectBoxItem fullWidth value="blox" icon={<BloxIcon />}>
                                            Blox
                                        </SelectBoxItem>
                                    )}
                                    {canCreateMixProjects && (
                                        <SelectBoxItem fullWidth value="mix" icon={<MixIcon />}>
                                            Mix
                                        </SelectBoxItem>
                                    )}
                                    {miniQuizAccessLevel === 'full' && (
                                        <SelectBoxItem fullWidth value="mini-quiz" icon={<QuestionCircleIcon />}>
                                            {t('miniQuiz::projectType')}
                                        </SelectBoxItem>
                                    )}
                                </SelectBox>
                            }
                        />
                    </div>
                )}

                <div>
                    <Collapse in={canSelectBlockGallery && watch('type') === 'blox'}>
                        <Controller
                            control={control}
                            name="blockGallery"
                            rules={{ required: watch('type') === 'blox' }}
                            as={
                                <Select
                                    data-testid="new-project-dialog-gallery"
                                    label={t('projects::palette')}
                                    disabled={status === 'loading'}
                                    displayEmpty
                                    error={!!errors.blockGallery}
                                    errorText={errors.blockGallery ? t('projects::paletteRequired') : undefined}
                                >
                                    <Option value="" disabled>
                                        {t('projects::selectPalette')}
                                    </Option>
                                    {blockGalleries.map(blockGallery => (
                                        <Option key={blockGallery.id} value={blockGallery.id}>
                                            {blockGallery.name}
                                        </Option>
                                    ))}
                                </Select>
                            }
                        />
                    </Collapse>

                    <Collapse in={canChooseLanguage && watch('type') === 'mix'}>
                        <Controller
                            control={control}
                            name="language"
                            as={
                                <Select label={t('projects::language::label')} disabled={status === 'loading'}>
                                    <Option value="python">Python</Option>
                                    <Option value="stagescript">StageScript</Option>
                                </Select>
                            }
                        />
                    </Collapse>
                </div>

                {hasGrant('project-labels') && (
                    <Collapse in={watch('type') !== 'mini-quiz'}>
                        <ProjectLabelSelect
                            variant="edit"
                            onChange={value => {
                                setValue('labels', value)
                            }}
                        />
                    </Collapse>
                )}
            </Stack>
        </DialogForm>
    )
}
